.inputForm {
    display: flex;
    align-items: center;
    white-space: nowrap;
    align-self: inherit;
}

.customInput {
    display: flex;
    width: 100%;
    padding: 5px 10px;
    margin: 5px 0;
    border: 1px solid teal;
}

.customLabel {
    width: 150px;
    display: inline-block;
    font: caption;
}