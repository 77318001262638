* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    width: 800px;
    margin: 20px;
}

.video-grid {
    display: table-row;
    margin-top: 20px;
}

.participant {
    display: flex;
    margin-top: 5px;
    border: 2px solid teal;
}

video {
    max-width: 640px;
    height: 480px;
}

.user-name {
    position: absolute;
    margin: 20px;
}

.buttons {
    display: flex;
    justify-content: space-between;
}
